import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { liveBlogCard } from 'lib/CustomPropTypes';
import { LiveBlogContext, VerticalContext } from 'lib/ContextTypes';
import InlineVideo from 'components/InlineVideo';
import { cardMarkup } from 'components/Card/CardUtils';
import { SocialShareIconList } from 'components/Social/SocialShareIconList';
import { ChatCardHeadline } from './ChatCardHeadline/index';
import { ChatCardTimeStamp } from './ChatCardTimestamp/index';
import { ChatCardByline } from './ChatCardByline/index';
import { ChatCardBylineThumbnail } from './ChatCardBylineThumbnail/index';
import { ChatCardAuthorCount } from './ChatCardAuthorCount/index';
import { ChatCardBylineNames } from './ChatCardBylineNames/index';
import { ChatCardReportingFrom } from './ChatCardReportingFrom/index';
import { ChatCardEyebrow } from './ChatCardEyebrow/index';

import styles from './styles.module.scss';
import { ChatCardFactCheck } from './ChatCardFactCheck/index';

const ChatCard = ({
  cardType,
  authors,
  content: {
    /**
     * markupAndEmbeds should be data from the API, and should be respected if
     * `visibleMarkupAndEmbeds` isn't passed in
     */
    markupAndEmbeds = [],
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
    hasShowMoreBreakpoint,
  },
  date,
  headline: { primary: primaryHeadline },
  isHeadlineHidden = false,
  id,
  path,
  cardData: videoData,
  socialUrl,
  className,
  parentStyle,
  reportingFrom = null,
  factCheckContent = null,
  presentation,
}) => {
  const { isLive } = useContext(LiveBlogContext);
  const vertical = useContext(VerticalContext);
  const [thumbnailsTapped, setThumbnailsTapped] = useState(false);
  const thumbnailRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  const [showHiddenMarkupAndEmbeds, setShowHiddenMarkupAndEmbeds] = React.useState(false);
  const cardClasses = classNames(
    styles['chat-blog-card'],
    className === 'isReply' && styles.isReply,
    'article-body__content',
    'w-100 mh0 fn',
    parentStyle,
  );

  useEffect(() => {
    const handleTouchEnd = (e) => {
      if (!e.composedPath().includes(thumbnailRef.current)
        && !e.composedPath().includes(dropdownRef.current)) {
        setThumbnailsTapped(false);
      }
    };

    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const totalAuthorCount = authors.length;
  const isSingleByline = totalAuthorCount === 1;
  const isMultiBylines = totalAuthorCount > 1;

  const icid = 'ed_npd_nn_nn_np_liveblog';
  const dataTrackCardUrlIcid = 'live-blog-card-link';

  /**
   * if `visibleMarkupAndEmbeds` was not generated and passed in, but markupAndEmbeds exists, then
   * we should use that array instead, which should be data coming from the API directly
   */
  const cardBodyItems = visibleMarkupAndEmbeds || markupAndEmbeds;

  return (
    <div
      className={cardClasses}
      key={id}
      id={id}
      data-testid="chat-card-container"
    >
      <ChatCardEyebrow typeEnum={presentation?.style} />
      <div className={styles['time-stamp-social-share-container']}>
        {date && (
          <ChatCardTimeStamp date={date} isLive={isLive} />
        )}
        {className !== 'isReply' && (
          <SocialShareIconList
            vertical={vertical}
            primaryHed={primaryHeadline}
            socialUrl={socialUrl}
            icid={icid}
          />
        )}
      </div>

      {!isHeadlineHidden && primaryHeadline && (
        <>
          <ChatCardHeadline headline={primaryHeadline} />
        </>
      )}

      <div className={classNames(styles['authors-container'], reportingFrom ? 'mb2' : 'mb3')}>
        {isSingleByline && (
          <ChatCardByline
            key={`byline-${authors[0]?.id}`}
            author={authors[0]}
            position={1}
          />
        )}
        {isMultiBylines && (
          <>
            <div
              className={classNames(styles['author-thumbnails'], { [styles.isTapped]: thumbnailsTapped })}
              onTouchEnd={(e) => {
                if (!e.nativeEvent.composedPath().includes(dropdownRef.current)) {
                  setThumbnailsTapped(!thumbnailsTapped);
                }
              }}
              ref={thumbnailRef}
              data-testid="author-thumbnails"
            >
              {authors.map((author, index) => (
                <>
                  {/* Biz logic: Display 2 author thumbnails when there are 2 authors else only show 1 */}
                  {totalAuthorCount === 2
                    ? (
                      <ChatCardBylineThumbnail
                        key={`bylinethumbnail-${author.id}`}
                        author={author}
                        position={index + 1}
                      />
                    )
                    : (index === 0
                      && (
                        <ChatCardBylineThumbnail
                          key={`bylinethumbnail-${author.id}`}
                          author={author}
                          position={index + 1}
                        />
                      )
                    )}
                </>
              ))}
              {totalAuthorCount > 2 && (
                <>
                  <ChatCardAuthorCount count={totalAuthorCount - 1} />
                </>
              )}
              <div className={styles['author-dropdown']} ref={dropdownRef} data-testid="author-dropdown">
                {authors.map((author, index) => (
                  <ChatCardByline
                    key={`dropdown-${author.id}`}
                    author={author}
                    position={index + 1}
                    classNames={styles['dropdown-item']}
                  />
                ))}
              </div>
            </div>
            <div className="authors-names">
              {authors.map((author, index) => (
                <ChatCardBylineNames
                  key={`bylinenames-${author.id}`}
                  author={author}
                  index={index}
                  totalAuthorCount={totalAuthorCount}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {reportingFrom && (
        <ChatCardReportingFrom text={reportingFrom} className="mb3" />
      )}
      <div
        data-icid={dataTrackCardUrlIcid}
        data-testid="chat-card-body-container"
      >
        {cardMarkup({
          bodyItems: cardBodyItems,
          path,
          showHiddenMarkupAndEmbeds,
          setShowHiddenMarkupAndEmbeds,
        })}

        {(factCheckContent && !hasShowMoreBreakpoint)
          && <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />}
        {showHiddenMarkupAndEmbeds
          ? (
            <>
              {cardMarkup({ bodyItems: hiddenMarkupAndEmbeds, path })}
              <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />
            </>
          )
          : (
            <div className={classNames(styles['hidden-show-more-content'], { dn: !hiddenMarkupAndEmbeds?.length })}>
              {cardMarkup({ bodyItems: hiddenMarkupAndEmbeds, path })}
              <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />
            </div>
          )}

        {cardType === 'VIDEO' && <InlineVideo video={videoData} />}
      </div>
    </div>
  );
};

ChatCard.propTypes = {
  ...liveBlogCard,
};

export { ChatCard };
